
.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}



.menuToggle {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 44px;
  height: 25px;
  border: none;
  background-color: transparent;
  transition: backgroundColor 0.3s;
  cursor: pointer;
}

.menuToggle:focus {
  outline: none;
}

.menuToggle span {
  display: block;
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
  height: 2px;
  background: white;
  transition: backgroundColor 0 0.3s;
}
.menuToggle[data-menu-active="true"] span {
  background: none;
}

.menuToggle span::before,
.menuToggle span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  content: "";
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0;
}

.menuToggle span::before {
  top: -8px;
  transition-property: top, transform;
}

.menuToggle span::after {
  bottom: -8px;
  transition-property: bottom, transform;
}

.menuToggle[data-menu-active="true"] span::before {
  background-color: white;
  top: 0;
  transform: rotate(45deg);
}

.menuToggle[data-menu-active="true"] span::after {
  background-color: white;
  bottom: 0;
  transform: rotate(-45deg);
}