@font-face {
  font-family: 'druk-wide';
  src: url('../public/fonts/DrukWideBold.ttf');
  /* src: url('https://ddy1csms58ecs.cloudfront.net/fonts/DrukWideBold.ttf'); */
}

:root {
  --color-primary: #44D62C;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: white;
  background-color: black;
  user-select: none;
 
  font-family: 'druk-wide', sans-serif;
}
* {
  box-sizing: border-box;
}

.sonikit-patch {
  display: none;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

button {
  font-size: 1em;
  padding: 8px 16px;
  border-radius: 50px;
  border: none;
  outline: none;
  font-family: 'druk-wide', sans-serif;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  background-color: white;
  color: black;
}
button svg{
  width: 1.2em;
  height: 1.2em;
  pointer-events: none;
}
button a{
  color: black;
}

a {
  text-decoration: none;
  color: inherit;
}


.password-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.password-cover form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
}
.password-cover button, .password-cover input{
  width: 100%;
}
.password-cover input {
  padding: 8px 16px;
  font-size: 1em;
}
.password-cover button {
  padding: 8px 16px;
  font-size: 1em;
}

.form-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}