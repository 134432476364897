.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: -10; */
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms ease, visibility 0ms 300ms, z-index 0ms 300ms;
}
.wrapper[data-active="true"] {
  /* z-index: 10; */
  pointer-events: all;
  visibility: visible;
  opacity: 1;
  transition: opacity 300ms ease, visibility 0ms, z-index 0ms;
}
.wrapper[data-backdrop-blur="true"] {
  backdrop-filter: blur(5px);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
}

.content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  color: black;
  border-radius: 8px;
  min-width: 400px;
  max-width: 500px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.wrapper[data-size='large'] .content {
  max-width: 700px;
}
@media (max-width: 800px) {
  .wrapper[data-size='large'] .content, .content {
    min-width: auto;
    max-width: 90%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header h1,.header h2, .header h3, .header h4, .header h5, .header h6 {
  margin: 0 !important;
}

.body {
  width: 100%;
  max-height: 80vh;
  
  padding: 4px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.body h1,.body h2,.body h3,.body h4,.body h5,.body h6 {
  margin: 0 !important;
}

.loadingCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255,0.5);
  backdrop-filter: blur(5px);
}

.loadingCover svg{
  width: 30px;
  height: 30px;
}

.close {
  cursor: pointer;
  font-weight: 900;
  display: inline;
  padding: 0;
  font-size: inherit;
  background: transparent;
}
.close svg{
  width: 1.4em;
  height: 1.4em;
  fill: black;
}