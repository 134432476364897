.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wrapper video{
  position: absolute;
  top: -999vh;
  left: -999vw;
  visibility: hidden;
}

.webGlWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.webGlWrapper canvas{
  width: 100%;
  height: 100%;
}

.linkHidden {
  position: absolute;
  top: -999vh;
  left: -999vw;
  visibility: hidden;
  display: none;
}

.playerAuth {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(255,255,255,0.5);
  backdrop-filter: blur(10px);
  padding: 8px 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}
.playerAuth svg{
  width: 1em;
  height: 1em;
  fill: white;
  pointer-events: none;
}

.playerAuthCta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.playerAuthCta button{
  background-color: black;
  color: white;
  width: 250px;
}
.playerAuthCta button svg{
  fill: white;
}