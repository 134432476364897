.button {
	position: relative;
  user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	text-wrap: nowrap;
	box-sizing: border-box;
	background-color: rgba(255,255,255,0.6);
	backdrop-filter: blur(10px);
	padding: 10px 16px;
	color: black;
	border: none;
	outline: none;
	border-radius: 50px;
	cursor: pointer;
	gap: 6px;
	font-size: 0.9em;
	letter-spacing: 1px;
	font-weight: 100;
	text-transform: uppercase;
}

.button[data-shape="circle"] {
	border-radius: 50%;
	border: 2px solid black;
}

.button[data-layout="text"] {
	padding: 0 !important;
	background: transparent;
	backdrop-filter: none;
	color: inherit;
	font-size: 1em;
}
.button[data-layout="text"] svg{
	fill: white;
}

.button[data-size="block"] {
	width: 100%;
}
.button[data-size="small"] {
	padding: 6px 10px;
	font-size: 0.8em;
}

.button[data-color="warning"] {
  background-color: var(--color-warning);
}

.button[data-color="white"] {
  background-color: white;
	color: black;
}

.button[data-color="outline"] {
  border: 1px solid var(--color-mute);
  border-radius: var(--border-radius);
	background-color: white;
	color: black;
}
.button[data-color="outline"][data-state='focus'] {
	border: 1px solid black;
}
.button[data-color="outline"][data-state='active'] {
	border: 1px solid black;
	background-color: black;
	color: white;;
}

.button[data-state='submitting'],
.button[data-state='loading'] {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}

.button svg {
	pointer-events: none;
	fill: black;
	width: 1.2em;
	height: 1.2em;
}