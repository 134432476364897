.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0);
  backdrop-filter: blur(0px);
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: all 500ms ease;
  padding-bottom: 90px;
}
.wrapper[data-menu-active="true"] {
  opacity: 1;
  background-color: rgba(0,0,0,0.7);
  backdrop-filter: blur(10px);
  pointer-events: all;
  transition: all 500ms ease;
}

.albumCta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 2em;
}

.album {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
}
.album img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}

.navItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}
.navItem > *{
  pointer-events: none;
}

.subtitle {
  font-size: 0.6em;
}

.socialIcons {

}