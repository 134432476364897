.wrapper {
  position: absolute;
  /* top: 65%; */
  bottom: 150px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  opacity: 0;
  pointer-events: none;
  transition: all 500ms ease;
  text-align: center;
}
.wrapper[data-active="true"] {
  opacity: 1;
  pointer-events: all;
  transition: all 500ms ease;
}
.wrapper[data-role="form"] {
  background-color: rgba(255,255,255,0.3);
  backdrop-filter: blur(10px);
  color: black;
  border-radius: 10px;
  padding: 10px;
}
.wrapper a{
  width: 100%;
}

.close {
  color: white;
}

.navBtn {
  position: absolute;
  bottom: 90px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.5);
  backdrop-filter: blur(10px);
}
.navBtn svg{
  pointer-events: none;
}

.navBtn[data-direction="prev"] {
  left: 20px;
}
.navBtn[data-direction="next"] {
  right: 20px;
}


.merchNav {
  position: absolute;
  top: -60px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.5);
  backdrop-filter: blur(10px);
}
.merchNav svg{
  pointer-events: none;
}

.merchNav[data-direction="prev"] {
  left: 0px;
}
.merchNav[data-direction="next"] {
  right: 0px;
}


.videoTitle {
  font-size: 2.5em;
}

.titleLogo {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  max-width: 500px;
  pointer-events: none;
  opacity: 0;
  transition: all 500ms ease;
}
.titleLogo[data-active="true"] {
  opacity: 1;
  transition: all 500ms ease;
}

.titleLogo svg{
  fill: white;
}


.merchTitle {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  margin: auto;
  width: 95%;
  max-width: 500px;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  
  text-align: center;
  font-size: 1.2em;
  text-transform: uppercase;
}

.productName{
  font-size: 1em;
  opacity: 0;
  transition: all 500ms ease;
}
.merchTitle[data-active="true"] .productName{
  opacity: 1;
  transition: all 500ms ease;
}

.merchSectionHeader {
  font-size: 1.5em;
}
@media (min-width: 500px) {
  .merchTitle {
    top: 80px;
  }
  .merchSectionHeader {
    font-size: 2.2em;
  }
}



.inputWrap {
  width: 100%;
}

.inputWrap input {
  font-size: 1em;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 400;
  font-style: normal;
  border: 2px solid black;
}






.saveContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.saveContent button{
  background-color: black;
  color: white;
  width: 250px;
}
.saveContent button svg{
  fill: white;
}

.talkShopWrapper {
  position: relative;
  height: 70vh;
}