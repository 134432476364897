.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0px);
  transition: all 500ms ease 500ms;
}
.wrapper[data-active="true"]  {
  backdrop-filter: blur(5px);
  transition: all 500ms ease;
}

.wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0;
  transition: opacity 500ms ease 500ms;
}
.wrapper[data-active="true"]::before  {
  opacity: 0.8;
  transition: opacity 500ms ease;
}


.content {
  position: relative;
  background-color: white;
  color: black;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  transform: scaleY(0);
  transition: all 500ms ease 500ms;
}
.wrapper[data-active="true"] .content{
  transform: scaleY(1);
  transition: all 500ms ease;
}

.inputGroup {
  opacity: 0;
  transition: all 500ms ease;
}
.wrapper[data-active="true"] .inputGroup{
  opacity: 1;
  transition: all 500ms ease 500ms;
}

.wrapper button {
  background-color: black;
  color: white;
  width: 100%;
}


.title {
  font-size: 1em;
}

.close {
  display: inline;
  cursor: pointer;
}
.close svg{
  fill: black;
  pointer-events: none;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.inputWrap {
  width: 100%;
}

.inputWrap input {
  font-size: 1em;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 400;
  font-style: normal;
  border: 2px solid black;
}

.disclaimer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  font-size: 0.6em;
  text-align: left;
  margin-top: 10px;
  font-family: sans-serif;
}

.disclaimer input{
  appearance: none;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.disclaimer input::before {
  content: "";
  width: 70%;
  height: 70%;
  background-color: black;
  display: none;
}
.disclaimer input:checked::before {
  display: block;
}

.disclaimer label {
  display: flex;
  margin-top: 4px;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  box-sizing: border-box;
  border: 1px solid black;
} 

.disclaimer a{
  color: black;
}

.skip {
  background-color: transparent !important;
  background: transparent !important;
  color: black !important;
  cursor: pointer;
  margin-top: 30px;
  font-size: 0.8em;
}

.error {
  font-size: 0.7em;
  color: red;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}