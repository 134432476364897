.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  position: relative;
  text-align: center;
  border: 2px solid white;
  width: 200px;
  max-width: 100%;
  padding: 4px;
  box-sizing: border-box;
  user-select: none;
  margin-top: 20px;
}

@media (max-width: 800px) {
  .bar {
    width: 80%;
  }
}

.bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: white;
  animation: loading 4.5s ease forwards;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

.text {
  position: relative;
  color: white;
  mix-blend-mode: difference;
  font-size: 0.7em;
  letter-spacing: 2px;
}