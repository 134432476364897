.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: rgba(0,0,0,0.8);
}

.iframeWrapper {
  aspect-ratio: 16 / 9;
  width: 98%;
  max-width: 800px;
}

.iframeWrapper iframe{
  width: 100%;
  height: 100%;
  object-fit: contain;
}